export class PaginationResult implements IPaginationResult {
  constructor(
    public hasBottom: boolean,
    public hasTop: boolean,
    public totalElementCount?: number
  ) {}
}

/**
 * Representation of a pagination result
 */
export interface IPaginationResult {
  /** Specifies if there are more elements that can be loaded after the current ones. */
  hasBottom: boolean;
  /** Specifies if there are more elements that can be loaded before the current ones. */
  hasTop: boolean;
  /** Specifies the total count of results regardless of what was already loaded. */
  totalElementCount?: number;
}
