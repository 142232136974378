import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

import { CelumInputsModule } from '@celum/common-components';
import { CelumPipesModule, DefaultDateAdapter } from '@celum/ng2base';

import { DatePickerWrapper } from './datepicker-wrapper';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatNativeDateModule, MatDatepickerModule, MatFormFieldModule, MatIcon, CelumInputsModule, CelumPipesModule],
  declarations: [DatePickerWrapper],
  providers: [
    {
      provide: DateAdapter,
      useClass: DefaultDateAdapter
    }
  ],
  exports: [DatePickerWrapper, CelumInputsModule, MatDatepickerModule, MatFormFieldModule]
})
export class CelumDatepickerWrapperModule {}
