import { CommonModule } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { CelumDialogModule } from '@celum/common-components';
import { UserExistsResponse } from '@celum/sacc/domain';
import { AppState, InvitationResourceService, LegacyAccountService, selectAccountActiveAccountId, selectAccountSharedAccessLink } from '@celum/sacc/shared';

import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';
import { EmailInputComponent } from '../email-input/email-input.component';
import { EmailRowComponent } from '../email-row/email-row.component';

export const INVITE_DIALOG_PANEL_CLASS = 'invite-dialog-overlay-pane';

@Component({
  selector: 'sacc-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrl: './invite-dialog.component.scss',
  // Needed to style the copy icon
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, CelumDialogModule, CopyToClipboardComponent, EmailInputComponent, EmailRowComponent]
})
export class InviteDialogComponent {
  @ViewChild(EmailInputComponent, { static: true })
  public panelClass = INVITE_DIALOG_PANEL_CLASS;
  public sharedAccessLink$: Observable<string> = this.store$.select(selectAccountSharedAccessLink);

  private emails: string[] = [];
  private activeAccountId$: Observable<string> = this.store$.select(selectAccountActiveAccountId);

  constructor(
    private accountService: LegacyAccountService,
    private invitationResourceService: InvitationResourceService,
    private store$: Store<AppState>,
    private dialogRef: MatDialogRef<InviteDialogComponent>
  ) {}

  public get emailList(): ReadonlyArray<string> {
    return this.emails;
  }

  public emailExists(email: string): Observable<UserExistsResponse> {
    return this.activeAccountId$.pipe(
      mergeMap(accId =>
        combineLatest([this.accountService.invitationExists(accId, email), this.invitationResourceService.exists(accId, email)]).pipe(
          map(([accountServiceExists, invitationServiceExists]) => ({ exists: accountServiceExists || invitationServiceExists, provisioningType: null }))
        )
      )
    );
  }

  public removeEmail(idx: number): void {
    if (this.emails.length < 2) {
      this.emails = [];
      return;
    }

    this.emails.splice(idx, 1);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onInvite(): void {
    this.dialogRef.close(this.emails);
  }

  public showEmail(isNew: boolean): boolean {
    return isNew;
  }

  public showValidationWarning(isNew: boolean): boolean {
    return !isNew;
  }

  public emailChanged(email: string): void {
    this.emails.push(email);
  }

  public isUnique(email: string): boolean {
    return !this.emails.includes(email);
  }
}
