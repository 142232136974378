import { MatSortable, Sort } from '@angular/material/sort';

import { AccountAccessStatus } from '@celum/authentication';
import { SortDirection } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

import { AccountMemberRole } from './account-member-role';

export enum AccountMemberFilterPrivileges {
  WORK = 'WORK',
  EXPERIENCE = 'EXPERIENCE'
}

export interface SelectedGroupItem {
  id: string;
  name: string;
}

export interface AccountMemberFilters extends SortablePaginationOption {
  nameOrEmail?: string;
  status?: AccountAccessStatus[];
  imported?: boolean;
  privileges?: AccountMemberFilterPrivileges[];
  roles?: AccountMemberRole[];
  groups?: SelectedGroupItem[];
}

export function createEmptyAccountMemberFilter(sort?: Sort): AccountMemberFilters {
  return {
    nameOrEmail: '',
    sort: sort || createDefaultAccountMemberFilterSort()
  };
}

export function createDefaultAccountMemberFilterSort(): Sort {
  return { active: 'name', direction: SortDirection.ASC };
}

export function createDefaultAccountMemberFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultAccountMemberFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}
