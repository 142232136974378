import { MatSortable, Sort } from '@angular/material/sort';

import { SortDirection } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

export interface UserGroupFilters extends SortablePaginationOption {
  name?: string;
  imported?: boolean;
}

export function createEmptyUserGroupFilter(sort?: Sort): UserGroupFilters {
  return {
    name: '',
    sort: sort || createDefaultUserGroupFilterSort()
  };
}

export function createDefaultUserGroupFilterSort(): Sort {
  return { active: 'name', direction: SortDirection.ASC };
}

export function createDefaultUserGroupFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultUserGroupFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}
