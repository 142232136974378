import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable, of, throwError } from 'rxjs';
import { catchError, map, reduce } from 'rxjs/operators';

import { ExperiencePrivilege, InvitationStatus, WorkroomPrivilege } from '@celum/authentication';
import { AccountMember, BatchDTO, BatchParams, BulkResultDTO, getPaginationParams, InvitationFilters } from '@celum/sacc/domain';

import { RestService } from '../services/rest.service';
import { Utils } from '../utils';

export interface InvitationChanges {
  invitationStatus?: InvitationStatus;
  privileges?: {
    work?: WorkroomPrivilege;
    experience?: ExperiencePrivilege;
  };
  groupIdsToAdd?: string[];
  groupIdsToRemove?: string[];
  resendInvitation?: boolean;
}

export interface InvitationUpdateRequest {
  workPrivilege?: WorkroomPrivilege;
  experiencePrivilege?: ExperiencePrivilege;
  groupIdsToAdd?: string[];
  groupIdsToRemove?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class InvitationResourceService extends RestService {
  private accountRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.accountRecordHeight);

  constructor(private httpClient: HttpClient) {
    super();
  }

  public searchNew(
    accountId: string,
    invitationStatusIn: InvitationStatus[],
    filter: InvitationFilters,
    batchParams: BatchParams = {}
  ): Observable<BatchDTO<AccountMember>> {
    return this.httpClient.post<BatchDTO<AccountMember>>(
      this.getApiUrl(`/accounts/${accountId}/members/.search`),
      { invitationStatusIn, nameOrEmailContains: filter.memberName, ...(filter.groups && { groupIdIn: filter.groups.map(group => group.id) }) },
      {
        params: getPaginationParams({
          ...batchParams,
          count: this.batchSize
        })
      }
    );
  }

  public inviteUsers(accountId: string, emails: string[]): Observable<BulkResultDTO<AccountMember, any>> {
    const bulkResult: BulkResultDTO<AccountMember, any> = {
      successful: [],
      failed: []
    };

    const obs = emails.map(email =>
      this.httpClient.post(this.getApiUrl(`/accounts/${accountId}/members`), { email }).pipe(
        map(json => json as AccountMember),
        catchError(error => of({ email, error }))
      )
    );

    return concat(...obs).pipe(
      reduce((acc, curr) => {
        if ((curr as any).invitationStatus !== undefined) {
          acc.successful.push(curr as AccountMember);
        } else {
          acc.failed.push(curr);
        }

        return acc;
      }, bulkResult)
    );
  }

  public updateInvitation(
    accountId: string,
    userId: string,
    changedValues: InvitationChanges,
    invitation?: AccountMember
  ): Observable<AccountMember | undefined> {
    const body: InvitationUpdateRequest = this.prepareUpdateRequest(changedValues);

    return this.httpClient.patch<void>(this.getApiUrl(`/accounts/${accountId}/members/${userId}`), body).pipe(map(() => invitation));
  }

  public acceptInvitation(accountId: string, currentUserId: string): Observable<string> {
    return this.httpClient
      .put(this.getApiUrl(`/accounts/${accountId}/members/${currentUserId}/invitation-status`), { invitationStatus: InvitationStatus.ACCEPTED })
      .pipe(map(() => accountId));
  }

  public resendInvitation(accountId: string, invitationId: string): Observable<AccountMember> {
    return this.httpClient.patch<AccountMember>(this.getApiUrl(`/accounts/${accountId}/members/${invitationId}`), { actions: ['RESEND_INVITE'] });
  }

  public rejectInvitation(accountId: string, invitationId: string): Observable<string> {
    return this.httpClient
      .put(this.getApiUrl(`/accounts/${accountId}/members/${invitationId}/invitation-status`), { invitationStatus: InvitationStatus.REJECTED })
      .pipe(map(() => accountId));
  }

  public removeInvitation(accountId: string, invitationId: string, invitationStatus: InvitationStatus): Observable<string> {
    return this.httpClient.delete(this.getApiUrl(`/accounts/${accountId}/members/${invitationId}`)).pipe(
      map(() => invitationId),
      catchError(error => {
        error.invitationStatus = invitationStatus;
        return throwError(() => error);
      })
    );
  }

  public approveAccountAccess({ id, accountId }: AccountMember): Observable<{ accountMember: AccountMember; id: string }> {
    return this.httpClient
      .put<AccountMember>(this.getApiUrl(`/accounts/${accountId}/members/${id}/invitation-status`), { invitationStatus: InvitationStatus.APPROVED })
      .pipe(
        map(accountAccess => {
          return {
            accountMember: accountAccess,
            id
          };
        })
      );
  }

  public disapproveAccountAccess({ id, accountId }: AccountMember): Observable<AccountMember> {
    return this.httpClient.put<AccountMember>(this.getApiUrl(`/accounts/${accountId}/members/${id}/invitation-status`), {
      invitationStatus: InvitationStatus.DISAPPROVED
    });
  }

  public exists(accountId: string, email: string): Observable<boolean> {
    return this.httpClient
      .post<{
        exists: boolean;
      }>(this.getApiUrl(`/accounts/${accountId}/members/exists`), {
        emailIn: [email],
        invitationStatusIn: [InvitationStatus.INVITED, InvitationStatus.PENDING_APPROVAL, InvitationStatus.REJECTED, InvitationStatus.DISAPPROVED]
      })
      .pipe(map(r => r.exists));
  }

  public requestAccountAccess(repositoryId: string, accountId: string, email: string): Observable<void> {
    return this.httpClient.post<void>(this.getApiUrl(`/accounts/${accountId}/members`), {
      repositoryId,
      email
    });
  }

  private prepareUpdateRequest(changedValues: InvitationChanges): InvitationUpdateRequest {
    const body: InvitationUpdateRequest = {};

    if (changedValues.privileges?.experience || changedValues.privileges?.work) {
      if (changedValues?.privileges.experience) {
        body.experiencePrivilege = changedValues.privileges.experience === ExperiencePrivilege.FULL_ACCESS ? ExperiencePrivilege.FULL_ACCESS : null;
      }
      if (changedValues?.privileges.work) {
        body.workPrivilege = changedValues.privileges.work;
      }
    }

    if (changedValues?.groupIdsToAdd || changedValues?.groupIdsToRemove) {
      if (changedValues.groupIdsToAdd) {
        body.groupIdsToAdd = changedValues.groupIdsToAdd;
      }
      if (changedValues.groupIdsToRemove) {
        body.groupIdsToRemove = changedValues.groupIdsToRemove;
      }
    }

    return body;
  }
}
