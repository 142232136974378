import { AfterViewInit, Directive, Input } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';

@Directive({
  selector: '[saccTableInitialSort][matSort]',
  standalone: true
})
export class TableInitialSortDirective implements AfterViewInit {
  @Input()
  public initialSort: MatSortable;

  constructor(private matSort: MatSort) {}

  public ngAfterViewInit(): void {
    setTimeout(() => this.matSort.sort(this.initialSort), 0);
  }
}
