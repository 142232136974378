<clickable-popup-dialog [enableHoverColorOnOpenedMenu]="false" [contentClass]="'celum-filter_popup'" (menuOpenedChanged)="onMenuOpenedChanged($event)">
  <div popupTrigger>
    <button celum-button class="celum-filter_trigger" data-test-sacc-user-group-filter-button mat-flat-button size="small">
      <div class="celum-filter_trigger-content">
        {{ 'COMMON.FILTER' | translate }}
        <celum-icon [configuration]="icons.filter"></celum-icon>
      </div>
    </button>
  </div>
  <div popupContent (click)="$event.stopPropagation()">
    <div class="celum-filter_menu" data-test-sacc-user-group-filter-menu (keydown)="onKeyDown($event)">
      @if (service.vm$ | async; as vm) {
        <!-- Member name search -->
        <div class="celum-filter_menu-section">
          <p class="celum-filter_menu-section-header celum-filter_menu-section-header-text">
            {{ 'COMPONENTS.USER_GROUPS.FILTER.SEARCH.TYPES.GROUP_NAME.TITLE' | translate }}
          </p>

          <lookup-area
            #firstSearchInput
            class="celum-filter_menu-search-input"
            data-test-sacc-user-group-filter-menu-search-group-name-input
            tabindex="0"
            [debounceTime]="300"
            [formFieldSize]="'small'"
            [placeholder]="'COMPONENTS.USER_GROUPS.FILTER.SEARCH.TYPES.GROUP_NAME.PLACEHOLDER' | translate"
            [value]="vm.filter.name"
            (searchValue)="groupNameSearchChanged($event)"></lookup-area>
        </div>

        <hr />

        <!-- Imported checkbox -->
        <div class="celum-filter_menu-checkbox-section celum-filter_menu-checkbox-section--bottom">
          <p class="celum-filter_menu-checkbox-section-header celum-filter_menu-checkbox-section-header-text">
            {{ 'COMPONENTS.USER_GROUPS.FILTER.SEARCH.TYPES.IMPORTED.TITLE' | translate }}
          </p>
          <button mat-button [disableRipple]="true" (click)="importedChanged()">
            <mat-checkbox
              class="celum-filter_menu-checkbox"
              data-test-sacc-user-group-filter-menu-checkbox-imported
              [checked]="vm.filter.imported"
              [disableRipple]="true"
              [labelPosition]="'before'"
              (change)="importedChanged()"
              (click)="$event.stopPropagation()">
              {{ 'COMPONENTS.USER_GROUPS.FILTER.SEARCH.TYPES.IMPORTED.PLACEHOLDER' | translate }}
            </mat-checkbox>
          </button>
        </div>

        <hr />

        <button
          class="celum-filter_menu-clear-all-btn"
          data-test-sacc-user-group-filter-menu-clear-all-button
          tabindex="4"
          type="button"
          [disabled]="vm.noFiltersApplied"
          (click)="clearAllFilters()">
          {{ 'SHARED.CLEAR_ALL' | translate }}
        </button>
      }
    </div>
  </div>
</clickable-popup-dialog>
