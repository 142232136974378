<clickable-popup-dialog [enableHoverColorOnOpenedMenu]="false" [contentClass]="'celum-filter_popup'" (menuOpenedChanged)="onMenuOpenedChanged($event)">
  <div popupTrigger>
    <button celum-button class="celum-filter_trigger" data-test-sacc-account-member-filter-button mat-flat-button size="small">
      <div class="celum-filter_trigger-content">
        {{ 'COMMON.FILTER' | translate }}
        <celum-icon [configuration]="icons.filter"></celum-icon>
      </div>
    </button>
  </div>
  <div popupContent (click)="$event.stopPropagation()">
    <div class="celum-filter_menu" data-test-sacc-account-member-filter-menu (keydown)="onKeyDown($event)">
      @if (service.vm$ | async; as vm) {
        <!-- Member name search -->
        <div class="celum-filter_menu-section">
          <p class="celum-filter_menu-section-header celum-filter_menu-section-header-text">
            {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.MEMBER_NAME.TITLE' | translate }}
          </p>

          <lookup-area
            #firstSearchInput
            class="celum-filter_menu-search-input"
            data-test-sacc-account-member-filter-menu-search-member-name-input
            tabindex="0"
            [debounceTime]="300"
            [formFieldSize]="'small'"
            [placeholder]="'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.MEMBER_NAME.PLACEHOLDER' | translate"
            [value]="vm.filter.nameOrEmail"
            (searchValue)="memberNameSearchChanged($event)"></lookup-area>
        </div>

        <hr />

        <!-- Status checkboxes -->
        @if (filterSections.get('COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE'); as statusFilterSectionValues) {
          <div class="celum-filter_menu-checkbox-section">
            <p class="celum-filter_menu-checkbox-section-header celum-filter_menu-checkbox-section-header-text">
              {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE' | translate }}
            </p>
            <button
              *ngFor="let item of statusFilterSectionValues"
              class="celum-filter_menu-checkbox-button"
              mat-button
              [disableRipple]="true"
              (click)="toggleFilter(item.value)">
              <mat-checkbox
                class="celum-filter_menu-checkbox celum-filter_menu-checkbox-status"
                [attr.data-test-sacc-account-member-filter-menu-status-checkbox]="item.value"
                [checked]="item | wrapFn: isFilterApplied : vm.filter"
                [disableRipple]="true"
                [labelPosition]="'before'"
                (change)="toggleFilter(item.value)"
                (click)="$event.stopPropagation()">
                <sacc-activation-status [activationStatus]="$any(item).value"></sacc-activation-status>
              </mat-checkbox>
            </button>
          </div>
        }

        <hr />

        <!-- Groups search list -->
        <div class="celum-filter_menu-checkbox-section celum-filter_menu-checkbox-section-top celum-filter_menu-checkbox-section-bottom">
          <sacc-filter-group-search
            [accountId]="vm.accountId"
            [selectedItems]="vm.filter.groups"
            [title]="'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.GROUPS.TITLE'"
            (selectedItemsChanged)="onSelectedGroupItemsChanged($event)"></sacc-filter-group-search>
        </div>

        <hr />

        <!-- Role checkboxes -->
        @if (filterSections.get('COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.ROLE.TITLE'); as roleFilterSectionValues) {
          <div class="celum-filter_menu-checkbox-section">
            <p class="celum-filter_menu-checkbox-section-header celum-filter_menu-checkbox-section-header-text">
              {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.ROLE.TITLE' | translate }}
            </p>
            <button
              *ngFor="let item of roleFilterSectionValues"
              class="celum-filter_menu-checkbox celum-filter_menu-checkbox-button"
              mat-button
              [disableRipple]="true"
              (click)="toggleFilter(item.value)">
              <mat-checkbox
                [attr.data-test-sacc-account-member-filter-menu-role-checkbox]="item.value"
                [checked]="item | wrapFn: isFilterApplied : vm.filter"
                [disableRipple]="true"
                [labelPosition]="'before'"
                (change)="toggleFilter(item.value)"
                (click)="$event.stopPropagation()">
                {{ item.title | translate }}
              </mat-checkbox>
            </button>
          </div>
        }

        <hr />

        <!-- Imported checkbox -->
        <div class="celum-filter_menu-checkbox-section">
          <p class="celum-filter_menu-checkbox-section-header celum-filter_menu-checkbox-section-header-text">
            {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.IMPORTED.TITLE' | translate }}
          </p>
          <button class="celum-filter_menu-checkbox-button" mat-button [disableRipple]="true" (click)="importedChanged()">
            <mat-checkbox
              data-test-sacc-account-member-filter-menu-checkbox-imported
              [checked]="vm.filter.imported"
              [disableRipple]="true"
              [labelPosition]="'before'"
              (change)="importedChanged()"
              (click)="$event.stopPropagation()">
              {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.IMPORTED.PLACEHOLDER' | translate }}
            </mat-checkbox>
          </button>
        </div>

        <hr />

        <button
          class="celum-filter_menu-clear-all-btn"
          data-test-sacc-account-member-filter-menu-clear-all-button
          tabindex="4"
          type="button"
          [disabled]="vm.noFiltersApplied"
          (click)="clearAllFilters()">
          {{ 'SHARED.CLEAR_ALL' | translate }}
        </button>
      }
    </div>
  </div>
</clickable-popup-dialog>
