import { Directive, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';

import { UserExistsResponse } from '@celum/sacc/domain';
import { UserResourceService } from '@celum/sacc/shared';

@Directive({
  selector: '[appUserAction]',
  standalone: true
})
export class UserActionDirective {
  public emails: string[] = [];

  public userExistsResponse = signal<UserExistsResponse>(undefined);

  constructor(
    protected dialogRef: MatDialogRef<any>,
    protected userService: UserResourceService
  ) {}

  public emailExists(email: string): Observable<UserExistsResponse> {
    return this.userService.exists(email).pipe(tap(response => this.userExistsResponse.set(response)));
  }

  public removeEmail(idx: number): void {
    if (this.emails.length < 2) {
      this.emails = [];
      return;
    }

    this.emails.splice(idx, 1);
  }

  public showEmail(isNew: boolean): boolean {
    return !isNew;
  }

  public showValidationWarning(isNew: boolean, email: string): string {
    return isNew && email;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onActionClick(): void {
    if (this.emails.length > 0) {
      this.dialogRef.close(this.emails);
    }
  }

  public emailChanged(email: string): void {
    this.emails.push(email);
  }

  public isUnique(email: string): boolean {
    return !this.emails.includes(email);
  }
}
