import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DataUtil } from '@celum/core';
import { CelumPipesModule } from '@celum/ng2base';
import { createEmptyInvitationFilter, InvitationFilters } from '@celum/sacc/domain';
import { CelumChipComponent, CelumChipSetComponent, FilterChip } from '@celum/shared/ui';

import { invitationFilterItemByValue } from '../invitation-filter/invitation-filter.item';

@Component({
  selector: 'sacc-invitation-filter-chips',
  templateUrl: './invitation-filter-chips.component.html',
  styleUrl: 'invitation-filter-chips.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, CelumChipComponent, CelumChipSetComponent, CelumPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InvitationFilterChipsComponent implements OnChanges {
  @HostBinding('class.celum-filter-chips') protected readonly hostClass = true;

  @Input() public filter: InvitationFilters;

  @Output() public readonly filterChanged = new EventEmitter<InvitationFilters>();

  protected chips = signal<FilterChip[]>([]);

  constructor(private translateService: TranslateService) {}

  public ngOnChanges({ filter }: SimpleChanges): void {
    this.chips.set(this.filterToChips(filter.currentValue));
  }

  protected trackFn(index: number): number {
    return index;
  }

  protected filterToChips(filters: InvitationFilters): FilterChip[] {
    const chips = [];

    if (!DataUtil.isEmpty(filters.memberName)) {
      chips.push({
        value: `"${filters.memberName}"`,
        title: '',
        onRemove: () =>
          this.filterChanged.emit({
            ...filters,
            memberName: ''
          })
      });
    }

    if (!DataUtil.isEmpty(filters.status)) {
      filters.status.forEach(value => {
        const filterItem = invitationFilterItemByValue[value];
        let displayValue = this.translateService.instant(`COMPONENTS.INVITATION_STATUS.${filterItem.value.toUpperCase()}`);
        displayValue = displayValue.replace(displayValue[0], displayValue[0].toUpperCase());
        chips.push({
          title: filterItem.typeTitle,
          value: displayValue,
          onRemove: () => this.filterChanged.emit({ ...filters, status: filters.status.filter(status => status !== filterItem.value) })
        });
      });
    }

    if (!DataUtil.isEmpty(filters.groups)) {
      filters.groups.forEach(group => {
        chips.push({
          title: 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.GROUPS.TITLE',
          value: group.name,
          onRemove: () => this.filterChanged.emit({ ...filters, groups: filters.groups.filter(existingGroup => existingGroup.id !== group.id) })
        });
      });
    }

    return chips;
  }

  protected clearAllFilters(): void {
    this.filterChanged.emit(createEmptyInvitationFilter(this.filter.sort));
  }
}
