import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { InvitationStatus } from '@celum/authentication';
import { RemoveSnackbar, ShowSnackbar, SimpleSnackbar, SnackbarConfiguration, SnackbarState } from '@celum/common-components';
import { ExecutableAction, tapAllResponses } from '@celum/core';
import { AccountMember } from '@celum/sacc/domain';
import { ErrorService } from '@celum/shared/util';

import { InvitationResourceService } from './invitation-resource.service';
import { LoaderService } from './loader.service';
import { NotificationService } from './notification.service';
import { ErrorFactory } from '../error.factory';
import { selectAccountActiveAccountId } from '../store/account/account.selectors';
import { accountMemberActions } from '../store/account-member/account-member.actions';
import { AppState } from '../store/app.state';
import { invitationActions } from '../store/invitation/invitation.actions';

@Injectable({
  providedIn: 'root'
})
export class InvitationService extends ComponentStore<any> {
  public resendInvitation = this.effect<string>(invitationId$ =>
    invitationId$.pipe(
      tap(() => this.loaderService.show()),
      concatLatestFrom(() => this.store$.select(selectAccountActiveAccountId)),
      switchMap(([invitationId, accountId]) => this.invitationResourceService.resendInvitation(accountId, invitationId)),
      tapAllResponses(
        invitation => {
          // TODO: Remove/Replace this once the componentstore here will store the actual invitations
          this.store$.dispatch(invitationActions.resendInvitationSuccess({ invitation }));
          this.notificationService.info('SERVICES.ACCOUNT_MEMBER.EFFECTS.INVITATION.RESEND_SUCCESS');
          this.loaderService.hide();
        },
        error => this.handleOperationFailure(error, 'SERVICES.ACCOUNT_MEMBER.EFFECTS.INVITATION.RESEND_FAILURE')
      )
    )
  );

  public approveAccountAccess = this.effect<{ invitation: AccountMember; displayOpenLink: boolean }>(invitation$ =>
    invitation$.pipe(
      tap(() => this.loaderService.show()),
      switchMap(({ invitation, displayOpenLink }) =>
        this.invitationResourceService.approveAccountAccess(invitation).pipe(map(result => ({ ...result, displayOpenLink })))
      ),
      tapAllResponses(
        ({ accountMember, id, displayOpenLink }) => {
          // Reset the accountMember table here, otherwise the fetchBatch-call would fail because it uses an invalid continuationToken (as we just modified the
          // list of approved members by adding a new member)
          this.store$.dispatch(accountMemberActions.resetAccountTable());
          this.store$.dispatch(accountMemberActions.fetchBatch());

          this.store$.dispatch(invitationActions.approveAccountAccessSuccess({ accountMember, id }));

          let snackbarConfiguration = SnackbarConfiguration.success(this.translateService.instant('SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.APPROVED'));
          if (displayOpenLink) {
            snackbarConfiguration = snackbarConfiguration.withActions({
              [SnackbarState.SUCCESS]: [
                new ExecutableAction(() => {
                  this.router.navigateByUrl(`/account-membership/${accountMember.accountId}/members?editMember=${accountMember.id}`);
                  this.store$.dispatch(new RemoveSnackbar(id));
                }, 'SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.APPROVED_ACTION')
              ]
            });
          }

          this.store$.dispatch(new ShowSnackbar(id, SimpleSnackbar, snackbarConfiguration));

          this.loaderService.hide();
        },
        error => this.handleOperationFailure(error, 'SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.APPROVED_FAILURE')
      )
    )
  );

  public disapproveAccountAccess = this.effect<AccountMember>(invitation$ =>
    invitation$.pipe(
      tap(() => this.loaderService.show()),
      switchMap(invitation => this.invitationResourceService.disapproveAccountAccess(invitation)),
      tapAllResponses(
        invitation => {
          // TODO: Remove/Replace this once the componentstore here will store the actual invitations
          this.store$.dispatch(invitationActions.disapproveAccountAccessSuccess({ invitation }));
          this.notificationService.info('SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.REJECTED');
          this.loaderService.hide();
        },
        error => this.handleOperationFailure(error, 'SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.REJECTED_FAILURE')
      )
    )
  );

  public deleteInvitation = this.effect<{ invitationId: string; invitationStatus: InvitationStatus }>(invitationId$ =>
    invitationId$.pipe(
      tap(() => this.loaderService.show()),
      concatLatestFrom(() => this.store$.select(selectAccountActiveAccountId)),
      switchMap(([{ invitationId, invitationStatus }, accountId]) =>
        combineLatest([this.invitationResourceService.removeInvitation(accountId, invitationId, invitationStatus), of(invitationStatus)])
      ),
      tapAllResponses(
        ([invitationId, invitationStatus]) => {
          // TODO: Remove/Replace this once the componentstore here will store the actual invitations
          this.store$.dispatch(invitationActions.deleteInvitationSuccess({ id: invitationId, invitationStatus }));
          this.notificationService.info(this.getDeleteInvitationSuccessMessageKey(invitationStatus));
          this.loaderService.hide();
        },
        error => {
          const messageKey = this.getDeleteInvitationFailureMessageKey((error as any).invitationStatus);
          this.handleOperationFailure(error, messageKey);
        }
      )
    )
  );

  constructor(
    private invitationResourceService: InvitationResourceService,
    private store$: Store<AppState>,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private router: Router
  ) {
    super({});
  }

  private getDeleteInvitationFailureMessageKey(invitationStatus: InvitationStatus): string {
    const prefix = 'SERVICES.ACCOUNT_MEMBER.EFFECTS';

    if (invitationStatus === InvitationStatus.DISAPPROVED) {
      return `${prefix}.DISAPPROVED_REQUEST.DELETION_FAILURE`;
    }
    if (invitationStatus === InvitationStatus.REJECTED) {
      return `${prefix}.REJECT_INVITATION.DELETION_FAILURE`;
    }
    if (invitationStatus === InvitationStatus.PENDING_APPROVAL) {
      return `${prefix}.PENDING_APPROVAL.DELETION_FAILURE`;
    }

    return `${prefix}.INVITATION.DELETION_FAILURE`;
  }

  private getDeleteInvitationSuccessMessageKey(invitationStatus: InvitationStatus): string {
    const prefix = 'SERVICES.ACCOUNT_MEMBER.EFFECTS';

    if (invitationStatus === InvitationStatus.DISAPPROVED) {
      return `${prefix}.DISAPPROVED_REQUEST.DELETION_SUCCESS`;
    }
    if (invitationStatus === InvitationStatus.REJECTED) {
      return `${prefix}.REJECT_INVITATION.DELETION_SUCCESS`;
    }
    if (invitationStatus === InvitationStatus.PENDING_APPROVAL) {
      return `${prefix}.PENDING_APPROVAL.DELETION_SUCCESS`;
    }

    return `${prefix}.INVITATION.DELETION_SUCCESS`;
  }

  private handleOperationFailure(error: any, messageKey: string): void {
    const specialErrorMessage = ErrorFactory.getErrorMessage(error, this.translateService);
    const specialError = specialErrorMessage ? { error: specialErrorMessage } : undefined;

    this.errorService.error('InvitationService', this.translateService.instant(messageKey, specialError), error);
    this.loaderService.hide();
  }
}
