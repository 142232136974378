import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { UserResourceService } from '@celum/sacc/shared';

import { UserActionDirective } from '../../directives/user-action.directive';

@Directive({ standalone: true })
export abstract class PromoteDialogDirective extends UserActionDirective {
  protected constructor(
    protected dialogRef: MatDialogRef<any>,
    protected userService: UserResourceService
  ) {
    super(dialogRef, userService);
  }

  public abstract get title(): string;

  public abstract get emailPlaceHolder(): string;

  public abstract get label(): string;

  public onPromote(): void {
    this.onActionClick();
  }
}
