import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CelumDialogModule, ColorConstants } from '@celum/common-components';

export interface DialogConfig {
  buttonClass: string;
  headlineClass: string;
  headlineImageUrl: string;
}

@Component({
  selector: 'sacc-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule, CelumDialogModule]
})
export class ConfirmationDialogComponent {
  public title: string;
  public caption: string;
  public description: string;
  public cancelButtonText: string;
  public confirmationButtonText: string;
  public hideConfirmButton = false;

  protected readonly ColorConstants = ColorConstants;

  protected type: 'info' | 'warning';

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.title = data.title;
    this.confirmationButtonText = data.confirmationButtonText;
    this.cancelButtonText = data.cancelButtonText;
    this.type = data.type;
    this.description = data.description;
    this.caption = data.caption;
    this.hideConfirmButton = data.hideConfirmButton;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.dialogRef.close('confirm');
  }
}
