import { createReducer, on } from '@ngrx/store';

import { createEmptyRepositoryFilter } from '@celum/sacc/domain';

import { repositoryActions } from './repository.actions';
import { initialRepositoryState, repositoryAdapter } from './repository.state';

export const repositoryReducer = createReducer(
  initialRepositoryState,
  on(repositoryActions.fetchBatch, state => ({ ...state, loading: true })),
  on(repositoryActions.fetchBatchFailure, state => ({ ...state, loading: false })),
  on(repositoryActions.fetchBatchSuccess, (state, { batch }) => ({
    ...repositoryAdapter.addMany(batch.entities, state),
    continuationToken: batch.continuationToken,
    totalCount: batch.totalCount,
    loading: false
  })),
  on(repositoryActions.search, (state, action) => ({
    ...state,
    loading: true,
    continuationToken: state.continuationToken,
    filter: action.filter
  })),
  on(repositoryActions.filterChanged, (state, action) => ({
    ...repositoryAdapter.removeAll(state),
    loading: true,
    continuationToken: '',
    filter: action.filter
  })),
  on(repositoryActions.searchFailure, state => ({ ...state, loading: false })),
  on(repositoryActions.searchSuccess, (state, { batch }) => {
    return {
      ...repositoryAdapter.addMany(batch.entities, state),
      continuationToken: batch.continuationToken,
      totalCount: batch.totalCount,
      loading: false
    };
  }),
  on(repositoryActions.sortChanged, (state, { sort }) => ({
    ...initialRepositoryState,
    sort,
    continuationToken: null
  })),
  on(repositoryActions.resetRepositoryTable, state => ({ ...initialRepositoryState, filter: state.filter })),
  on(repositoryActions.resetRepositoryTableFilter, state => ({ ...state, filter: createEmptyRepositoryFilter() })),
  on(repositoryActions.registerSuccess, (state, action) => ({
    ...repositoryAdapter.upsertOne(action.repository, {
      ...state,
      totalCount: state.totalCount + 1
    })
  })),
  on(repositoryActions.updateSuccess, (state, action) => ({ ...repositoryAdapter.upsertOne(action.repository, state) })),
  on(repositoryActions.getOneSuccess, (state, action) => ({ ...repositoryAdapter.upsertOne(action.repository, state) })),
  on(repositoryActions.deleteSuccess, (state, { repositoryId }) => repositoryAdapter.removeOne(repositoryId, { ...state, totalCount: state.totalCount - 1 }))
);
