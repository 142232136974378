import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, UserService } from '@celum/authentication';
import { AppState, selectUserIsAccountManager } from '@celum/sacc/shared';
import { ErrorService } from '@celum/shared/util';

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate {
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private userService: UserService,
    private errorService: ErrorService
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<true | UrlTree> {
    /* This covers 2 routes: /myaccount and /myaccount/{accountId}.
      /myaccount should be only accessible for account managers and users with trial plan.
      /myaccount/{accountId} should be only accessible for managers of that given account. */
    return combineLatest([this.store$.select(selectUserIsAccountManager), this.userService.currentUser$]).pipe(
      map(([manager, user]) => {
        const url = state.url;
        if (url.endsWith('/myaccount') && (manager || user.trialPlan)) {
          return true;
        } else {
          // We have to read the accountId from the url, but it is not yet set as selected in the store
          const urlSplit = url.split('/');
          const accountId = urlSplit[urlSplit.length - 1];
          const validAccountAccess = user.accountAccesses.find(accountAccess => accountAccess.accountId === accountId);
          if (accountId && validAccountAccess?.status === AccountAccessStatus.ACTIVE && validAccountAccess?.role === AccountUserRole.MANAGER) {
            return true;
          }
        }

        this.errorService.error('AccountGuard', 'HTTP_INTERCEPTOR.NOT_FOUND');

        return this.router.parseUrl('/home');
      })
    );
  }
}
