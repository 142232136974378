<celum-dialog class="sacc-invite-dialog" data-test-invite-dialog (onCloseAll)="onCancel()">
  <celum-dialog-header class="sacc-invite-dialog_top">
    <span>{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.HEADER' | translate }}</span>
  </celum-dialog-header>

  <celum-dialog-content>
    <div class="sacc-invite-dialog_content">
      <h3>{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.SHARE_THIS_LINK' | translate }}</h3>
      <div class="sacc-form-field">
        <mat-label>{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.SHARE_THIS_LINK_HINT' | translate }}</mat-label>
        <div class="sacc-invite-dialog_shared-access-link-input-container">
          <mat-form-field readonly>
            <input
              class="celum-input sacc-invite-dialog_shared-access-link-input"
              data-test-invite-dialog-invitation-link-input
              matInput
              readonly
              type="text"
              [value]="sharedAccessLink$ | async" />
            <sacc-copy-to-clipboard
              class="clipboard-copy"
              failureMessage="{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.SHARE_FAILURE' | translate }}"
              matSuffix
              successMessage="{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.SHARE_SUCCESS' | translate }}"
              tooltip="{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.SHARE_THIS_LINK' | translate }}"
              [copyValue]="sharedAccessLink$ | async"
              [showCopyIcon]="true"></sacc-copy-to-clipboard>
          </mat-form-field>
        </div>
      </div>

      <h3>{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.INVITE_THROUGH_EMAIL' | translate }}</h3>

      <div class="sacc-invite-dialog_email-input">
        <sacc-email-input
          [emailExists]="emailExists.bind(this)"
          [emailFiltered]="isUnique.bind(this)"
          [emailHint]="'COMPONENTS.DIALOG.INVITE_DIALOG.INVITE_THROUGH_EMAIL_HINT' | translate"
          [emailPlaceHolder]="'COMPONENTS.DIALOG.INVITE_DIALOG.EMAIL_PLACEHOLDER' | translate"
          [emailStatusIndicator]="'New'"
          [label]="'COMPONENTS.DIALOG.INVITE_DIALOG.EMAIL_HINT' | translate"
          [showEmail]="showEmail.bind(this)"
          [showValidationWarning]="showValidationWarning.bind(this)"
          [validationWarningMessage]="'COMPONENTS.DIALOG.INVITE_DIALOG.EMAIL_VALIDATION_MESSAGE' | translate"
          (emailChanged)="emailChanged($event)"></sacc-email-input>
        <div class="sacc-invite-dialog_email-input-scroll-area">
          @for (email of emailList; track email; let i = $index) {
            <ng-container class="celum-scrollbar">
              <sacc-email-row [email]="email" (remove)="removeEmail(i)"></sacc-email-row>
            </ng-container>
          }
        </div>
      </div>
    </div>
  </celum-dialog-content>

  <celum-dialog-footer
    [confirmBtnText]="'COMPONENTS.DIALOG.INVITE_DIALOG.INVITE'"
    [valid]="emailList.length > 0"
    (onCancel)="onCancel()"
    (onConfirm)="onInvite()"></celum-dialog-footer>
</celum-dialog>
