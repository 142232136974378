import { Sort } from '@angular/material/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { AccountMember, createEmptyInvitationFilter, InvitationFilters } from '@celum/sacc/domain';

export interface InvitationState extends EntityState<AccountMember> {
  loading: boolean;
  sort: Sort;
  continuationToken: string;
  totalCount: number;
  filter: InvitationFilters;
}

export const userInvitationAdapter: EntityAdapter<AccountMember> = createEntityAdapter<AccountMember>();

export const initialInvitationState: InvitationState = userInvitationAdapter.getInitialState({
  sort: null,
  continuationToken: '',
  loading: false,
  totalCount: 0,
  filter: createEmptyInvitationFilter()
});
