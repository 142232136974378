import { NgClass } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';

@Component({
  selector: 'sacc-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrl: './navigation-item.component.scss',
  standalone: true,
  imports: [NgClass, RouterLink, CelumIconModule]
})
export class NavigationItemComponent {
  protected active = signal(true);

  protected internalLink = signal('');

  protected currentIcon = signal<IconConfiguration>(undefined);

  private iconDefault: IconConfiguration;
  private iconActive: IconConfiguration;

  @Input()
  public set icon(icon: string | IconConfiguration) {
    this.iconDefault = typeof icon === 'string' ? IconConfiguration.small(icon) : icon;
    this.iconActive = IconConfiguration.clone(this.iconDefault).withColor(ColorConstants.SYSTEM_WHITE);
    this.updateIcon();
  }

  @Input()
  public set link(link: string) {
    this.internalLink.set(link);
    this.updateIcon();
  }

  @Input()
  public title: string;

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed()
      )
      .subscribe(() => this.updateIcon());
  }

  private updateIcon() {
    if (!DataUtil.isEmpty(this.internalLink()) && this.iconDefault) {
      const url = this.router.createUrlTree([this.internalLink()]);
      this.active.set(
        this.router.isActive(url, {
          paths: 'subset',
          queryParams: 'ignored',
          fragment: 'ignored',
          matrixParams: 'ignored'
        })
      );
      this.currentIcon.set(this.active() ? this.iconActive : this.iconDefault);
    }
  }
}
