@if (service.vm$ | async; as vm) {
  <div class="sacc-group-selector">
    <h3>{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.HEADER' | translate }}</h3>
    <div class="sacc-group-selector_container">
      @if (!vm.isReadonly) {
        <div class="sacc-group-selector_group-filter">
          <sacc-group-filter
            data-test-group-selector-groups-control
            [accountId]="vm.accountId"
            [addedGroups]="vm.addedGroups"
            [disabled]="vm.isGroupFilteringDisabled"
            [removedGroups]="vm.removedGroups"
            [member]="vm.member"
            (groupAdded)="service.addGroup($event)"
            (groupRemoved)="service.removeGroup($event)"></sacc-group-filter>
        </div>
      }
      @if (vm.isReadonly) {
        <div class="sacc-group-selector_label">
          {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.GROUP_MEMBERS' | translate }}
        </div>
      }
      <div class="sacc-group-selector_member-count">
        <celum-table-count
          data-test-group-selector-groups-count
          [count]="vm.totalElementCount"
          [countAsParameter]="true"
          [countLabelPlural]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.MEMBER_COUNTS'"
          [countLabelSingular]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.MEMBER_COUNT'"></celum-table-count>
      </div>
      @if (vm.totalElementCount === 0) {
        <empty-page
          class="sacc-group-selector_group-list sacc-group-selector_member-list--empty"
          data-test-group-selector-groups-empty
          [config]="noResultsConfig"></empty-page>
      } @else {
        <celum-simple-list
          class="sacc-group-selector_group-list"
          data-test-group-selector-groups-list
          [hasMoreBottom]="vm.hasBottom"
          [items]="vm.groupsToShow"
          [itemTemplate]="itemTemplate"
          [trackByFn]="trackByFn"></celum-simple-list>
      }

      <ng-template #itemTemplate let-item>
        @if (!item.isAdded) {
          <div class="sacc-group-selector_group-item" data-test-group-selector-group-item>
            <celum-avatar
              class="sacc-group-selector_group-item-avatar"
              [class.disabled-item]="item.status === UserState.INACTIVE"
              [configuration]="item | groupToAvatarConfig"></celum-avatar>
            <div
              class="sacc-group-selector_group-item-name"
              data-test-group-selector-groups-item-name
              spaceAwareTooltip
              [class.disabled-item]="item.status === UserState.INACTIVE"
              [matTooltip]="item.name">
              {{ item.name }}
            </div>
            @if (!vm.isReadonly) {
              <sacc-icon-button
                class="sacc-group-selector_group-item-button"
                data-test-group-selector-groups-item-button-remove
                [class.acc-group-selector_group-item-button--disabled]="item.imported"
                [disabled]="item.imported"
                [iconConfiguration]="item.imported ? icons.removeDisabled : icons.remove"
                [matTooltip]="
                  (item.imported
                    ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.REMOVE_DISABLED_PROVISIONED'
                    : 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.REMOVE'
                  ) | translate
                "
                (click)="!item.imported && service.removeGroup(item)"></sacc-icon-button>
            }
          </div>
        }
      </ng-template>
    </div>
  </div>
}
