import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, UserService } from '@celum/authentication';
import { CelumIconModule } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { SaccProperties } from '@celum/sacc/domain';
import { ProfileComponent } from '@celum/sacc/ui';
import { SystembarComponent, SystembarConfiguration } from '@celum/shared/ui';

import { CollapseButtonComponent } from './collapse-button/collapse-button.component';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';

export interface LandingPageViewModel {
  adminLoggedIn: boolean;
  canOpenMyOrg: boolean;
  canOpenUserGroups: boolean;
  canOpenAccountMembership: boolean;
  showMyOrgSection: boolean;
  showAdminList: boolean;
}

@Component({
  selector: 'sacc-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  imports: [
    CommonModule,
    RouterOutlet,
    TranslateModule,

    MatSidenavModule,

    CelumIconModule,
    SystembarComponent,

    CollapseButtonComponent,
    NavigationItemComponent,
    ProfileComponent
  ]
})
export class LandingPageComponent {
  private readonly isAdminListEnabled = SaccProperties.properties.featureFlags?.adminList;

  protected vm$: Observable<LandingPageViewModel> = this.userService.currentUser$.pipe(
    isTruthy(),
    map(currentUser => {
      const hasTrialPlan = !!currentUser.trialPlan && !!currentUser.trialPlan.accountRequestId;
      const accounts = currentUser.accountAccesses;
      const managedAccounts = accounts.filter(account => account.status === AccountAccessStatus.ACTIVE && account.role === AccountUserRole.MANAGER);
      const isAccountManager = managedAccounts.length > 0;
      const isAdmin = currentUser.admin;
      const canOpenMyOrg = isAccountManager || hasTrialPlan;
      const canOpenUserGroups = managedAccounts.length > 0;

      return {
        adminLoggedIn: isAdmin,
        showMyOrgSection: canOpenMyOrg || canOpenUserGroups || isAccountManager,
        canOpenMyOrg,
        canOpenUserGroups,
        canOpenAccountMembership: (isAccountManager || isAdmin) && managedAccounts.length > 0,
        showAdminList: this.isAdminListEnabled
      };
    })
  );

  protected systembarConfiguration: SystembarConfiguration = {
    itemProvider: () => []
  };

  constructor(private userService: UserService) {}
}
