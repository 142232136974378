import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, switchMap, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService, ExperiencePrivilege, InvitationStatus, LicenseType, WorkroomPrivilege } from '@celum/authentication';
import { RemoveSnackbar, ShowSnackbar, SimpleSnackbar, SnackbarConfiguration, SnackbarState } from '@celum/common-components';
import { DataUtil, ExecutableAction, tapAllResponses } from '@celum/core';
import { Account, AccountMember, UserGroup } from '@celum/sacc/domain';
import { accountMemberActions, AppState, invitationActions, InvitationChanges, InvitationResourceService, NotificationService } from '@celum/sacc/shared';
import { ErrorService } from '@celum/shared/util';

import { EditInvitationDialogComponent } from './edit-invitation-dialog.component';
import { AccountMemberStatusSwitcherConfig } from '../account-member-status-switcher/account-member-status-switcher.component';

export type EditInvitationDialogState = {
  editedInvitation?: any; // have to cast this for now, because status of SaccAccountUser != status of AccountUser
  currentUser?: AccountMember;
  token?: string;
  tokenExpiration?: number;
  userGroup?: UserGroup;
  isReadonly?: boolean;
  addedGroups: UserGroup[];
  removedGroups: UserGroup[];
  account?: Account;
  isRefreshingToken?: boolean;
  currentStatus?: InvitationStatus;
  resendInvitation: boolean;
  isPopupOpen?: boolean;
};

export type EditInvitationDialogViewModel = {
  hasExperienceAccess: boolean;
  hasExperienceLicense: boolean;
  hasWorkroomLicense: boolean;
  isEditMode: boolean;
  statusSwitcherConfig: AccountMemberStatusSwitcherConfig;
  canPrivilegesBeEdited?: boolean;
  displayName?: string;
} & Omit<EditInvitationDialogState, 'userGroup'>;

export interface EditInvitationFormValue {
  status?: InvitationStatus;
  workroomPrivilege?: WorkroomPrivilege;
  experiencePrivilege?: ExperiencePrivilege;
  resendInvitation?: boolean;
}

@Injectable()
export class EditInvitationDialogService extends ComponentStore<EditInvitationDialogState> {
  public vm$ = this.select(this.state$, state => this.createViewModel(state));

  public updateInvitation = this.effect<InvitationChanges>(update$ =>
    update$.pipe(
      switchMap(changes => {
        if (!EditInvitationDialogService.requestingOnlyResendInvitation(changes)) {
          return this.invitationResourceService
            .updateInvitation(this.get().editedInvitation.accountId, this.get().editedInvitation.id, changes)
            .pipe(map(() => changes));
        }
        return of(changes);
      }),
      tapAllResponses<InvitationChanges, HttpErrorResponse>({
        next: changes => {
          if (!changes.resendInvitation) {
            this.dialogRef.close(true);
          }
        },
        error: error => this.handleErrorAndCloseDialog(error)
      }),
      switchMap(changes => {
        if (changes.resendInvitation) {
          return this.invitationResourceService.resendInvitation(this.get().editedInvitation.accountId, this.get().editedInvitation.id).pipe(map(() => true));
        }
        return of(false);
      }),
      tapAllResponses<boolean, HttpErrorResponse>(
        invitationResent => {
          if (invitationResent) {
            this.notificationService.info('SERVICES.ACCOUNT_MEMBER.EFFECTS.INVITATION.RESEND_SUCCESS');
            this.dialogRef.close(true);
          }
        },
        error => this.handleErrorAndCloseDialog(error)
      )
    )
  );

  public disapproveInvitation = this.effect<AccountMember>(disapprove =>
    disapprove.pipe(
      switchMap(invitation => this.invitationResourceService.disapproveAccountAccess(invitation)),
      tapAllResponses(
        invitation => {
          this.store$.dispatch(invitationActions.disapproveAccountAccessSuccess({ invitation }));
          this.notificationService.info('SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.REJECTED');
          this.dialogRef.close(true);
        },
        error => {
          const errors = this.extractErrorsFromResponse(error as HttpErrorResponse);
          if (!DataUtil.isEmpty(errors)) {
            errors.forEach(errorKey => this.errorService.error('EditInvitationDialogService', errorKey, error));
          } else {
            this.errorService.httpError(error as HttpErrorResponse, 'EditInvitationDialogService');
          }

          this.dialogRef.close(false);
        }
      )
    )
  );

  public approveInvitation = this.effect<{ invitation: AccountMember; displayOpenLink: boolean }>(approve =>
    approve.pipe(
      switchMap(({ invitation, displayOpenLink }) =>
        this.invitationResourceService.approveAccountAccess(invitation).pipe(map(result => ({ ...result, displayOpenLink })))
      ),
      tapAllResponses(
        ({ accountMember, id, displayOpenLink }) => {
          this.store$.dispatch(accountMemberActions.resetAccountTable());
          this.store$.dispatch(accountMemberActions.fetchBatch());
          this.store$.dispatch(invitationActions.approveAccountAccessSuccess({ accountMember, id }));

          let snackbarConfiguration = SnackbarConfiguration.success(this.translateService.instant('SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.APPROVED'));
          if (displayOpenLink) {
            snackbarConfiguration = snackbarConfiguration.withActions({
              [SnackbarState.SUCCESS]: [
                new ExecutableAction(() => {
                  this.router.navigateByUrl(`/account-membership/${accountMember.accountId}/members?editMember=${accountMember.id}`);
                  this.store$.dispatch(new RemoveSnackbar(id));
                }, 'SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCESS_REQUEST.APPROVED_ACTION')
              ]
            });
          }

          this.store$.dispatch(new ShowSnackbar(id, SimpleSnackbar, snackbarConfiguration));

          this.dialogRef.close(true);
        },
        error => {
          const errors = this.extractErrorsFromResponse(error as HttpErrorResponse);
          if (!DataUtil.isEmpty(errors)) {
            errors.forEach(errorKey => this.errorService.error('EditInvitationDialogService', errorKey, error));
          } else {
            this.errorService.httpError(error as HttpErrorResponse, 'EditInvitationDialogService');
          }

          this.dialogRef.close(false);
        }
      )
    )
  );

  constructor(
    private authService: AuthService,
    private invitationResourceService: InvitationResourceService,
    protected dialogRef: MatDialogRef<EditInvitationDialogComponent>,
    private errorService: ErrorService,
    private store$: Store<AppState>,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router
  ) {
    super({
      addedGroups: [],
      removedGroups: [],
      resendInvitation: false
    });

    this.select(state => state)
      .pipe(
        filter(state => !state.isRefreshingToken && (!state.token || state.tokenExpiration === undefined || Date.now() > state.tokenExpiration)),
        switchMap(() => {
          this.patchState({ isRefreshingToken: true });
          return this.authService.getAuthResult().pipe(
            tap(result => {
              this.patchState({ tokenExpiration: result.expiresOn.getTime(), token: result.accessToken, isRefreshingToken: false });
            })
          );
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  public init(editedInvitation: AccountMember, account: Account, currentUser: AccountMember): void {
    this.patchState({ editedInvitation: { ...editedInvitation }, account, currentUser, currentStatus: editedInvitation.invitationStatus });
  }

  public getChangedValues(formValue: EditInvitationFormValue, initialEditedInvitation: AccountMember): InvitationChanges {
    const changedValues: Partial<InvitationChanges> = {};

    if (formValue.status !== initialEditedInvitation.invitationStatus) {
      changedValues.invitationStatus = formValue.status;
    }

    const addedGroups = this.get().addedGroups;
    if (!DataUtil.isEmpty(addedGroups)) {
      changedValues.groupIdsToAdd = addedGroups.map(group => group.id);
    }

    const removedGroups = this.get().removedGroups;
    if (!DataUtil.isEmpty(removedGroups)) {
      changedValues.groupIdsToRemove = removedGroups.map(group => group.id);
    }

    if (formValue.workroomPrivilege && formValue.workroomPrivilege !== initialEditedInvitation.privileges.work) {
      changedValues.privileges = { ...changedValues.privileges, work: formValue.workroomPrivilege };
    }

    if (formValue.experiencePrivilege && formValue.experiencePrivilege !== initialEditedInvitation.privileges.experience) {
      changedValues.privileges = { ...changedValues.privileges, experience: formValue.experiencePrivilege };
    }

    if (this.get().resendInvitation) {
      changedValues.resendInvitation = true;
    }

    return changedValues;
  }

  private createViewModel(state: EditInvitationDialogState): EditInvitationDialogViewModel {
    const isEditMode = !!state.userGroup && !state.isReadonly;
    const hasWorkroomLicense = state.account.licenses.find(license => license.type === LicenseType.WORK)?.active;
    const hasExperienceLicense = state.account.licenses.find(license => license.type === LicenseType.EXPERIENCE)?.active;

    return {
      ...state,
      hasExperienceAccess: (state.editedInvitation as AccountMember).privileges.experience === ExperiencePrivilege.FULL_ACCESS,
      hasWorkroomLicense,
      hasExperienceLicense,
      isEditMode,
      statusSwitcherConfig: EditInvitationDialogService.getStatusSwitcherConfig(state),
      resendInvitation: state.resendInvitation,
      canPrivilegesBeEdited: ![InvitationStatus.PENDING_APPROVAL, InvitationStatus.REJECTED].includes(state.editedInvitation.invitationStatus),
      displayName:
        state.editedInvitation.firstName && state.editedInvitation.lastName
          ? `${state.editedInvitation.firstName} ${state.editedInvitation.lastName}`
          : undefined
    };
  }

  private extractErrorsFromResponse(error: HttpErrorResponse): string[] {
    return error?.error?.errors.map((errorElement: { errorKey: string }) => errorElement.errorKey);
  }

  private handleErrorAndCloseDialog(error: HttpErrorResponse) {
    const errors = this.extractErrorsFromResponse(error);
    if (!DataUtil.isEmpty(errors)) {
      errors.forEach(errorKey => this.errorService.error('EditInvitationDialogService', errorKey, error));
    } else {
      this.errorService.httpError(error, 'EditInvitationDialogService');
    }

    this.dialogRef.close(false);
  }

  private static getStatusSwitcherConfig(state: EditInvitationDialogState): AccountMemberStatusSwitcherConfig {
    let config = {
      memberOrInvitation: state.editedInvitation,
      valueNotYetChanged: state.editedInvitation.invitationStatus === state.currentStatus,
      isInvitation: true
    } as AccountMemberStatusSwitcherConfig;

    if (state.editedInvitation.invitationStatus === InvitationStatus.INVITED) {
      const enableResend = state.editedInvitation.resendAvailableAt && new Date(state.editedInvitation.resendAvailableAt).getTime() <= Date.now();
      config = {
        ...config,
        buttonPrimaryIcon: 'mail-l',
        buttonPrimaryLabel: 'COMPONENTS.INVITATION_TABLE.OPTIONS.RESEND',
        disabled: !enableResend,
        buttonPrimaryTooltip: !enableResend ? 'COMPONENTS.INVITATION_TABLE.OPTIONS.RESEND_INVITATION_DISABLED' : '',
        switchedPrimaryTextHeader: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.RESEND_INFO_HEADER',
        switchedPrimaryText: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.RESEND_INFO_TEXT'
      };
    } else if (state.editedInvitation.invitationStatus === InvitationStatus.PENDING_APPROVAL) {
      config = {
        ...config,
        buttonPrimaryIcon: 'check-m',
        buttonPrimaryLabel: 'COMPONENTS.INVITATION_TABLE.OPTIONS.ACCEPT',
        buttonSecondaryIcon: 'cancel-m',
        buttonSecondaryLabel: 'COMPONENTS.INVITATION_TABLE.OPTIONS.REJECT',
        disabled: false,
        switchedPrimaryTextHeader: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.ACCEPT_INFO_HEADER',
        switchedPrimaryText: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.ACCEPT_INFO_TEXT',
        switchedSecondaryTextHeader: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.REJECT_INFO_HEADER',
        switchedSecondaryText: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.REJECT_INFO_TEXT'
      };
    } else if (state.editedInvitation.invitationStatus === InvitationStatus.REJECTED) {
      config = {
        ...config,
        buttonPrimaryIcon: 'mail-l',
        buttonPrimaryLabel: 'COMPONENTS.INVITATION_TABLE.OPTIONS.RESEND',
        switchedPrimaryTextHeader: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.RESEND_INFO_HEADER',
        switchedPrimaryText: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.RESEND_INFO_TEXT'
      };
    } else if (state.editedInvitation.invitationStatus === InvitationStatus.DISAPPROVED) {
      config = {
        ...config,
        buttonPrimaryIcon: 'check-m',
        buttonPrimaryLabel: 'COMPONENTS.INVITATION_TABLE.OPTIONS.ACCEPT',
        switchedPrimaryTextHeader: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.ACCEPT_DISAPPROVED_INFO_HEADER',
        switchedPrimaryText: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.ACCEPT_DISAPPROVED_INFO_TEXT'
      };
    }

    return config;
  }

  private static requestingOnlyResendInvitation(changes: InvitationChanges) {
    const keys = Object.keys(changes);
    return keys.length === 1 && keys[0] === 'resendInvitation' && changes.resendInvitation === true;
  }
}
