import { MatSortable, Sort } from '@angular/material/sort';

import { SortDirection } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

export enum AccountFilterLicenseType {
  WORK = 'WORK',
  EXPERIENCE = 'EXPERIENCE',
  DRIVE = 'DRIVE',
  CONTENT = 'CONTENT_HUB'
}

export interface AccountFilters extends SortablePaginationOption {
  orgId?: string;
  orgName?: string;
  owner?: string;
  createdOnFrom?: Date;
  createdOnTo?: Date;
  activeLicenses?: AccountFilterLicenseType[];
}

export function createEmptyAccountFilter(sort?: Sort): AccountFilters {
  return {
    orgId: '',
    orgName: '',
    owner: '',
    createdOnFrom: undefined,
    createdOnTo: undefined,
    sort: sort || createDefaultAccountFilterSort()
  };
}

export function createDefaultAccountFilterSort(): Sort {
  return { active: 'name', direction: SortDirection.ASC };
}

export function createDefaultAccountFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultAccountFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}
