import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { InvitationStatus } from '@celum/authentication';
import { CelumDirectivesModule } from '@celum/ng2base';

@Component({
  selector: 'sacc-invitation-status',
  templateUrl: `./invitation-status.component.html`,
  styleUrls: ['./invitation-status.component.scss'],
  imports: [CommonModule, TranslateModule, MatTooltipModule, CelumDirectivesModule]
})
export class InvitationStatusComponent {
  @HostBinding('class.sacc-invitation-status') public hostCls = true;
  @HostBinding('class') public statusClass = '';

  protected internalInvitationStatus: InvitationStatus;

  @Input()
  public set invitationStatus(value: InvitationStatus) {
    this.internalInvitationStatus = value;
    this.statusClass = value.toLocaleLowerCase().replace('_', '-');
  }

  get invitationStatusText(): string {
    // The enum contains underscore
    return `COMPONENTS.INVITATION_STATUS.${this.internalInvitationStatus.toUpperCase()}`;
  }
}
