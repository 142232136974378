<celum-standard-dialog
  data-test-sacc-promote-dialog
  [confirmBtnText]="'COMPONENTS.DIALOG.ABSTRACT_PROMOTION_DIALOG.PROMOTE' | translate"
  [disableOnConfirmClosing]="true"
  [headerText]="title"
  [valid]="emails.length > 0"
  (onCancel)="onCancel()"
  (onCloseAll)="onCancel()"
  (onConfirm)="onPromote()">
  <sacc-email-input
    data-test-sacc-promote-dialog-email-input
    [emailExists]="emailExists.bind(this)"
    [emailFiltered]="isUnique.bind(this)"
    [emailHint]=""
    [emailPlaceHolder]="emailPlaceHolder"
    [label]="label"
    [showEmail]="showEmail.bind(this)"
    [showValidationWarning]="showValidationWarning.bind(this)"
    [validationWarningMessage]="'COMPONENTS.DIALOG.ABSTRACT_PROMOTION_DIALOG.EMAIL_VALIDATION_MESSAGE' | translate"
    (emailChanged)="emailChanged($event)"></sacc-email-input>
  @for (email of emails; track email; let i = $index) {
    <sacc-email-row [attr.data-test-sacc-promote-dialog-email-list-item]="email" [email]="email" (remove)="removeEmail(i)"></sacc-email-row>
  }
  <ng-container></ng-container>
</celum-standard-dialog>
