import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AVATAR_SIZE, CelumLookupAreaModule, IconConfiguration } from '@celum/common-components';
import { isArrayEqual } from '@celum/core';
import { CelumDirectivesModule, CelumPipesModule } from '@celum/ng2base';
import { SelectedGroupItem, UserGroup } from '@celum/sacc/domain';
import { GroupToAvatarConfigPipe, UserGroupResourceService } from '@celum/sacc/shared';

import { GroupAvatarComponent } from '../group-avatar/group-avatar.component';

export interface GroupDisplayItem extends UserGroup {
  isChecked: boolean;
}

@Component({
  selector: 'sacc-filter-group-search',
  templateUrl: 'filter-group-search.component.html',
  styleUrl: 'filter-group-search.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Needed to style the group avatar
  encapsulation: ViewEncapsulation.None,
  imports: [MatButtonModule, MatCheckboxModule, CelumDirectivesModule, CelumLookupAreaModule, CelumPipesModule, GroupAvatarComponent, GroupToAvatarConfigPipe]
})
export class FilterGroupSearchComponent implements AfterViewInit {
  @Input() public title: string;
  @Input() public accountId: string;
  @Input() public maxNumberGroupResultsShown = 10;

  @Output() public readonly selectedItemsChanged = new EventEmitter<SelectedGroupItem[]>();

  protected readonly AVATAR_SIZE = AVATAR_SIZE;

  protected searchTerm = signal('');
  protected displayItems = signal<GroupDisplayItem[]>([]);
  protected showSearchMoreItem = signal(false);

  protected iconNoGroup = new IconConfiguration('fallback-groups').withIconSize(40);

  private internalSelectedItems: GroupDisplayItem[] = [];
  private lastSearchResult: GroupDisplayItem[] = [];

  constructor(private userGroupResourceService: UserGroupResourceService) {}

  @Input() public set selectedItems(selectedItems: SelectedGroupItem[]) {
    const finalSelectedItems = selectedItems || [];
    const previousSelectedItems = [...this.internalSelectedItems];
    this.updateSelectedItems(finalSelectedItems);
    if (!isArrayEqual(previousSelectedItems, this.internalSelectedItems)) {
      this.updateDisplayItems();
    }
  }

  public ngAfterViewInit(): void {
    // Initial search to populate the list
    this.searchTermChanged('');
  }

  protected searchTermChanged(searchTerm: string): void {
    this.searchTerm.set(searchTerm);
    this.userGroupResourceService
      .getUserGroupsForAccount(this.accountId, { filter: searchTerm, limit: this.maxNumberGroupResultsShown })
      .pipe(
        tap(result => this.showSearchMoreItem.set(result.paginationInfo.totalElementCount > this.maxNumberGroupResultsShown)),
        map(result => result.data),
        map(groupItems => groupItems.map(groupItem => ({ ...groupItem, isChecked: false })))
      )
      .subscribe(finalResult => {
        this.lastSearchResult = [...finalResult];
        this.updateDisplayItems();
      });
  }

  protected toggleSelectedGroup(item: GroupDisplayItem): void {
    if (item.isChecked) {
      this.internalSelectedItems = this.internalSelectedItems.filter(selectedItem => selectedItem.id !== item.id);
    } else {
      this.internalSelectedItems.push({ ...item, isChecked: true });
    }

    this.selectedItemsChanged.emit(this.internalSelectedItems.map(selectedItem => ({ id: selectedItem.id, name: selectedItem.name })));

    this.updateDisplayItems();
  }

  private updateDisplayItems() {
    /*
       List content:
       * No search performed:
         ** Selected items on top (whether they appear in the search result or not!)
         ** Selectable items
         ** If over 25 items: "Search to find more"-item (not selectable)
       * Search performed:
         ** Selected items on top (if they also appear in the search result)
         ** Selectable items of the search result
         ** If over 25 items: "Search to find more"-item (not selectable)
    */
    let selectedItems: GroupDisplayItem[] = [];

    if (this.searchTerm() === '') {
      selectedItems = [...this.internalSelectedItems];
    } else {
      selectedItems = this.lastSearchResult.filter(item => !!this.internalSelectedItems.find(selectedItem => selectedItem.id === item.id));
      selectedItems.forEach(selectedItem => (selectedItem.isChecked = true));
    }

    const unselectedItems = this.lastSearchResult.filter(item => selectedItems.find(selectedItem => selectedItem.id === item.id) === undefined);
    this.displayItems.set([...selectedItems, ...unselectedItems]);
  }

  private updateSelectedItems(selectedItems: SelectedGroupItem[]) {
    // Check not yet checked items
    selectedItems.forEach(selectedItem => {
      const foundItem = this.internalSelectedItems.find(item => item.id === selectedItem.id);
      if (!foundItem) {
        const lastItem = this.lastSearchResult.find(searchItem => selectedItem.id === searchItem.id);
        if (lastItem) {
          this.internalSelectedItems.push(lastItem);
        }
      }
    });

    // Find items that were unchecked and remove them
    const idsToRemove = this.internalSelectedItems
      .filter(item => selectedItems.find(selectedItem => selectedItem.id === item.id) === undefined)
      .map(item => item.id);
    if (idsToRemove.length > 0) {
      this.internalSelectedItems = this.internalSelectedItems.filter(item => !idsToRemove.includes(item.id));
    }
  }
}
