@if (service.vm$ | async; as vm) {
  <ng-container class="sacc-user-groups-list" data-test-sacc-user-table>
    <div class="sacc-user-groups-list_header">
      <celum-table-header>
        <celum-table-count
          ngProjectAs="table-header-counter"
          [count]="vm.userGroupsCountFiltered"
          [countLabelPlural]="'COMPONENTS.USER_GROUPS.LIST.COUNTS'"
          [countLabelSingular]="'COMPONENTS.USER_GROUPS.LIST.COUNT'"></celum-table-count>
        <sacc-user-group-filter-chips
          ngProjectAs="table-header-filter-chips"
          [filter]="vm.filter"
          (filterChanged)="service.filterChanged($event)"></sacc-user-group-filter-chips>
        <sacc-user-group-filter ngProjectAs="table-header-filter" [filter]="vm.filter" (filterChanged)="service.filterChanged($event)"></sacc-user-group-filter>
      </celum-table-header>
    </div>

    @if (vm.userGroupsCount >= maxGroupCount) {
      <message-box
        class="sacc-user-groups-list_limit-reached"
        [text]="'COMPONENTS.USER_GROUPS.LIST.LIMIT_REACHED' | translate: { groupLimit: maxGroupCount }"
        [type]="'warn'"></message-box>
    }

    <table
      aria-label="User groups table"
      class="sacc-user-groups-list_table"
      infiniteScroll
      matSort
      mat-table
      saccTableInitialSort
      [dataSource]="vm.userGroups"
      [fromRoot]="true"
      [infiniteScrollContainer]="scrollContainer"
      [initialSort]="createDefaultUserGroupFilterMatSortable()"
      [matSortDisableClear]="true"
      [trackBy]="userGroupTracker"
      (matSortChange)="sortData($event)"
      (scrolled)="!vm.allLoaded && !vm.loading && service.loadNextBatch()">
      <ng-container matColumnDef="groupAvatar">
        <th *matHeaderCellDef mat-header-cell>&nbsp;</th>
        <td *matCellDef="let element; let i = index" mat-cell [attr.data-test-sacc-user-groups-list-row-avatar]="element.id">
          <sacc-group-avatar [config]="element | groupToAvatarConfig"></sacc-group-avatar>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="name">{{ 'COMPONENTS.USER_GROUPS.LIST.HEADERS.NAME' | translate }}</th>
        <td *matCellDef="let element" mat-cell [attr.data-test-sacc-user-groups-list-row-name]="element.id">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="imported">
        <th *matHeaderCellDef="let element" id="imported-header" mat-header-cell mat-sort-header="imported">
          {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.IMPORTED' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell [attr.data-test-sacc-user-groups-list-row-status]="element.id" [ngClass]="element.status">
          @if (element.imported) {
            <celum-icon [configuration]="icons.imported" [matTooltip]="'COMPONENTS.USER_GROUPS.LIST.IMPORTED_TOOLTIP' | translate"></celum-icon>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef id="actions-header" mat-header-cell>&nbsp;</th>
        <td *matCellDef="let element" mat-cell [attr.data-test-sacc-user-groups-list-row-actions]="element.id">
          @if (vm.userCanManageGroup && !vm.isReadonly) {
            <sacc-icon-button
              [attr.data-test-sacc-user-groups-list-row-action-button]="element.id"
              [iconConfiguration]="icons.menu"
              [matMenuTriggerFor]="menu"
              [matTooltip]="'COMPONENTS.TABLE.TOOLTIPS.MORE_ACTIONS' | translate"
              (click)="$event.stopPropagation()"></sacc-icon-button>
          }
          <mat-menu #menu="matMenu">
            <ng-container>
              <button
                data-test-sacc-user-groups-list-row-actions-menu-delete-button
                [disabled]="element.imported"
                [matTooltip]="'COMPONENTS.USER_GROUPS.LIST.IMPORTED_DELETE_DISABLED_TOOLTIP' | translate"
                [matTooltipDisabled]="!element.imported"
                mat-menu-item
                (click)="deleteGroup(element)">
                <celum-icon [configuration]="icons.delete"></celum-icon>
                <span>{{ 'COMPONENTS.USER_GROUPS.LIST.DELETE_GROUP' | translate }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="vm.displayedColumns; sticky: true" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: vm.displayedColumns; let index = index"
        class="sacc-user-groups-list_table-row"
        data-test-sacc-user-groups-list-row
        mat-row
        [attr.data-test-sacc-user-groups-list-row]="row.id"
        (click)="openGroupDialog(row, vm.activeAccountAccess, !vm.userCanManageGroup || vm.isReadonly || row.imported, vm.account)"></tr>
    </table>

    @if (!vm.userGroupsCountFiltered && !vm.loading) {
      <empty-page class="sacc-user-groups-list_no-results-found" [config]="noResultsConfig"></empty-page>
    }

    @if (vm.loading) {
      <div class="sacc-user-groups-list_loading">
        <mat-spinner [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
      </div>
    }
  </ng-container>
}
