import { MatSortable, Sort } from '@angular/material/sort';

import { InvitationStatus } from '@celum/authentication';
import { SortDirection } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

import { SelectedGroupItem } from './account-member-filter';

export interface InvitationFilters extends SortablePaginationOption {
  memberName?: string;
  status?: InvitationStatus[];
  groups?: SelectedGroupItem[];
}

export function createEmptyInvitationFilter(sort?: Sort): InvitationFilters {
  return {
    memberName: '',
    sort: sort || createDefaultInvitationFilterSort()
  };
}

export function createDefaultInvitationFilterSort(): Sort {
  return { active: 'name', direction: SortDirection.ASC };
}

export function createDefaultInvitationFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultInvitationFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}
