import { MatSortable, Sort } from '@angular/material/sort';

import { SortDirection } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

export interface AdminFilters extends SortablePaginationOption {
  name?: string;
}

export function createEmptyAdminFilter(sort?: Sort): AdminFilters {
  return {
    name: '',
    sort: sort || createDefaultAdminFilterSort()
  };
}

export function createDefaultAdminFilterSort(): Sort {
  return { active: 'promotedAt', direction: SortDirection.ASC };
}

export function createDefaultAdminFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultAdminFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}
