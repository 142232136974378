import { MatSortable, Sort } from '@angular/material/sort';

import { ProvisioningType } from '@celum/authentication';
import { SortDirection } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

import { FederationType } from './federation';

export interface FederationFilters extends SortablePaginationOption {
  name?: string;
  domainHint?: string;
  federationType?: FederationType[];
  provisioningType?: ProvisioningType[];
}

export function createEmptyFederationFilter(sort?: Sort): FederationFilters {
  return {
    name: '',
    domainHint: '',
    sort: sort || createDefaultFederationFilterSort()
  };
}

export function createDefaultFederationFilterSort(): Sort {
  return { active: 'name', direction: SortDirection.ASC };
}

export function createDefaultFederationUserFilterSort(): Sort {
  return { active: 'displayName', direction: SortDirection.ASC };
}

export function createDefaultFederationGroupFilterSort(): Sort {
  return { active: 'displayName', direction: SortDirection.ASC };
}

export function createDefaultFederationFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultFederationFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}

export function createDefaultFederationUserFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultFederationUserFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}

export function createDefaultFederationGroupFilterMatSortable(disableClear = true): MatSortable {
  const sort = createDefaultFederationGroupFilterSort();
  return { id: sort.active, start: sort.direction, disableClear };
}
