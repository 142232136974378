import { Component, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { CelumDialogModule } from '@celum/common-components';
import { UserResourceService } from '@celum/sacc/shared';

import { PromoteDialogDirective } from './promote-dialog.directive';
import { EmailInputComponent } from '../email-input/email-input.component';
import { EmailRowComponent } from '../email-row/email-row.component';

@Component({
  selector: 'sacc-promote-admin-dialog',
  templateUrl: './promote-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [TranslateModule, MatAutocompleteModule, MatButtonModule, MatDividerModule, MatIconModule, CelumDialogModule, EmailInputComponent, EmailRowComponent]
})
export class PromoteAdminDialogComponent extends PromoteDialogDirective {
  constructor(
    protected dialogRef: MatDialogRef<PromoteAdminDialogComponent>,
    protected userService: UserResourceService,
    private translateService: TranslateService
  ) {
    super(dialogRef, userService);
  }

  public get title(): string {
    return this.translateService.instant('COMPONENTS.DIALOG.PROMOTE_ADMINS_DIALOG.HEADER');
  }

  public get emailPlaceHolder(): string {
    return this.translateService.instant('COMPONENTS.DIALOG.PROMOTE_ADMINS_DIALOG.EMAIL_PLACEHOLDER');
  }

  public get label(): string {
    return this.translateService.instant('COMPONENTS.DIALOG.PROMOTE_ADMINS_DIALOG.EMAIL');
  }
}
