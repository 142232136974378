import { MatSort, MatSortable } from '@angular/material/sort';

import { SaccPaginationResult } from '@celum/sacc/domain';
import { Result } from '@celum/shared/util';

import { EntitiesResult } from './entities-result';

export class Utils {
  private static readonly emailRegex =
    /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

  private static readonly domainAndEmailRegex =
    /^([a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@)??(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

  public static isEmail(input: string): boolean {
    return Utils.emailRegex.test(input.toLowerCase());
  }

  public static isEmailOrDomain(input: string): boolean {
    return Utils.domainAndEmailRegex.test(input.toLowerCase());
  }

  public static getAccountLogo(accountLogoDownloadLink: string, accountAccessToken: string): string {
    if (accountLogoDownloadLink && accountAccessToken) {
      return `${accountLogoDownloadLink}&token=${accountAccessToken}`;
    }
    return 'assets/images/missing-company-logo.svg';
  }

  public static calculateBatchSize(accountRecordHeight: number): number {
    // 1.2 is a magic number that adds a little bit of extra buffer to the batchsize
    return Math.round((window.innerHeight / accountRecordHeight) * 1.2);
  }

  public static mapToResult<T>(result: EntitiesResult<T>, offset: number): Result<T> {
    return {
      paginationInfo: new SaccPaginationResult(!!result.continuationToken, offset !== 0, result.totalCount),
      data: result.entities
    };
  }

  /**
   * Sorts the given sort component if it is activated and either the sorted column or direction are not matching the given default
   */
  public static sortIfNotDefault(matSort: MatSort, defaultSort: MatSortable): void {
    if (matSort?.active && (matSort.active !== defaultSort.id || matSort.direction !== defaultSort.start)) {
      matSort.sort(defaultSort);
    }
  }

  /**
   * Converts a given hex-color to rgb notation
   * @param hex
   */
  public static hexToRgb(hex: string): string {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : null;
  }
}
