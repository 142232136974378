<div class="sacc-landing-page" data-test-sacc-landing-page>
  <shared-systembar [configuration]="systembarConfiguration"></shared-systembar>

  <mat-drawer-container class="sacc-landing-page_app-navigation">
    <mat-drawer-content>
      <mat-drawer-container class="sacc-landing-page_user-navigation">
        <mat-drawer mode="side" opened #drawer>
          <sacc-profile></sacc-profile>
          <nav *ngIf="vm$ | async as vm">
            <sacc-navigation-item
              class="sacc-landing-page_user-navigation-item"
              data-test-sacc-landing-page-navigation-item-home
              [icon]="'home'"
              [link]="'/home'"
              [title]="'COMPONENTS.LANDING_PAGE.HOME'"></sacc-navigation-item>

            @if (vm.showMyOrgSection) {
              <span class="sacc-landing-page_user-navigation-header" data-test-sacc-landing-page-header-my-organisation>
                {{ 'COMPONENTS.LANDING_PAGE.MY_ORGANIZATION' | translate }}
              </span>

              <sacc-navigation-item
                *ngIf="vm.canOpenMyOrg"
                class="sacc-landing-page_user-navigation-item"
                data-test-sacc-landing-page-navigation-item-my-account
                [icon]="'details-m'"
                [link]="'/myaccount'"
                [title]="'COMPONENTS.LANDING_PAGE.ORG_DETAILS'"></sacc-navigation-item>

              <sacc-navigation-item
                *ngIf="vm.canOpenAccountMembership"
                class="sacc-landing-page_user-navigation-item"
                data-test-sacc-landing-page-navigation-item-account-membership
                [icon]="'user-m'"
                [link]="'/account-membership'"
                [title]="'COMPONENTS.LANDING_PAGE.ACCOUNT_MEMBERSHIP'"></sacc-navigation-item>

              <sacc-navigation-item
                *ngIf="vm.canOpenUserGroups"
                class="sacc-landing-page_user-navigation-item"
                data-test-sacc-landing-page-navigation-item-user-groups
                [icon]="'user-in-sacc'"
                [link]="'/user-groups'"
                [title]="'COMPONENTS.LANDING_PAGE.USER_GROUPS'"></sacc-navigation-item>
            }

            @if (vm.adminLoggedIn) {
              <span class="sacc-landing-page_user-navigation-header" data-test-sacc-landing-page-header-administration>
                {{ 'COMPONENTS.LANDING_PAGE.ADMINISTRATION' | translate }}
              </span>

              <sacc-navigation-item
                *ngIf="vm.showAdminList"
                class="sacc-landing-page_user-navigation-item"
                data-test-sacc-landing-page-navigation-item-admin-list
                [icon]="'moderator-remove'"
                [link]="'/admins'"
                [title]="'COMPONENTS.LANDING_PAGE.ADMIN_LIST'"></sacc-navigation-item>

              <sacc-navigation-item
                class="sacc-landing-page_user-navigation-item"
                data-test-sacc-landing-page-navigation-item-accounts
                [icon]="'organisation'"
                [link]="'/accounts'"
                [title]="'COMPONENTS.LANDING_PAGE.ACCOUNT_ADMINISTRATION'"></sacc-navigation-item>

              <sacc-navigation-item
                class="sacc-landing-page_user-navigation-item"
                data-test-sacc-landing-page-navigation-item-repositories
                [icon]="'storage'"
                [link]="'/repositories'"
                [title]="'COMPONENTS.LANDING_PAGE.REPOSITORY_ADMINISTRATION'"></sacc-navigation-item>

              <sacc-navigation-item
                class="sacc-landing-page_user-navigation-item"
                data-test-sacc-landing-page-navigation-item-federations
                [icon]="'federation'"
                [link]="'/federations'"
                [title]="'COMPONENTS.LANDING_PAGE.FEDERATION_ADMINISTRATION'"></sacc-navigation-item>
            }
          </nav>
          <sacc-collapse-button [opened]="drawer.opened" (collapse)="drawer.toggle()"></sacc-collapse-button>
        </mat-drawer>
        <mat-drawer-content class="inner-drawer-content">
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
