import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import { AccountAccess, AccountUserRole, UserDetails } from '@celum/authentication';
import { tapAllResponses } from '@celum/core';
import { Account, createDefaultUserGroupFilterSort, createEmptyUserGroupFilter, SaccPaginationResult, UserGroup, UserGroupFilters } from '@celum/sacc/domain';
import { UserGroupResourceService, UserGroupsFindOptions } from '@celum/sacc/shared';
import { ErrorService, PagedListState, Result, SimplePagedListService } from '@celum/shared/util';

export type UserGroupsListState = {
  allLoaded: boolean;
  loading: boolean;
  user?: UserDetails;
  isReadonly: boolean;
  hasBottom?: boolean;
  displayedColumns: string[];
  firstNotZeroUserGroupCount: number;
  userGroupsCount: number;
  maxGroupCount?: number;
  filter: UserGroupFilters;
  account?: Account;
  enableAdminEditPrivilegesOverride: boolean;
};

export type UserGroupsListViewModel = {
  userGroups: UserGroup[];
  activeAccountAccess: AccountAccess;
  userGroupsCountFiltered: number;
  userCanManageGroup: boolean;
} & UserGroupsListState;

const USER_GROUPS_LIST_SERVICE = 'UserGroupsListService';

@Injectable()
export class UserGroupsListService extends SimplePagedListService<UserGroup, UserGroupsListState> {
  public vm$ = this.select(state => this.createViewModel(state));

  public deleteUserGroup = this.effect<string>(group$ =>
    group$.pipe(
      switchMap(id => this.userGroupService.deleteUserGroup(this.get().account.id, id).pipe(map(() => id))),
      tapAllResponses(
        () => this.load(),
        (error: HttpErrorResponse) => {
          this.errorService.httpError(error, USER_GROUPS_LIST_SERVICE);
        }
      )
    )
  );

  constructor(
    private userGroupService: UserGroupResourceService,
    private errorService: ErrorService
  ) {
    super(
      {
        serverCall: (offset: number, batchSize: number) => this.loadUserGroups(offset, batchSize),
        batchSize: 20
      },
      {
        allLoaded: false,
        loading: false,
        isReadonly: false,
        displayedColumns: [],
        firstNotZeroUserGroupCount: 0,
        userGroupsCount: 0,
        filter: createEmptyUserGroupFilter(),
        enableAdminEditPrivilegesOverride: false
      }
    );
  }

  public setAccount(account: Account): void {
    this.patchState({ account, firstNotZeroUserGroupCount: 0, filter: createEmptyUserGroupFilter() });
    if (account && this.get().user) {
      this.load();
    }
  }

  public setUser(user: UserDetails): void {
    this.patchState({ user });
    if (user && this.get().account?.id) {
      this.load();
    }
  }

  public init(displayedColumns: string[]): void {
    this.patchState({ displayedColumns });
  }

  public sortChanged(sort: Sort) {
    const filterCopy = { ...this.get().filter };
    filterCopy.sort = sort.direction !== '' ? sort : createDefaultUserGroupFilterSort();
    this.patchState({ filter: filterCopy });
    this.load();
  }

  public filterChanged(filter: UserGroupFilters): void {
    this.patchState({ filter });
    this.load();
  }

  private loadUserGroups(offset: number, batchSize: number): Observable<Result<UserGroup>> {
    this.patchState({ loading: true });
    const filter = this.get().filter;
    const options: UserGroupsFindOptions = {
      offset,
      limit: batchSize,
      filter: filter.name,
      sort: filter.sort
    };

    if (filter.imported) {
      options.imported = filter.imported;
    }

    return this.userGroupService.getUserGroupsForAccount(this.get().account?.id, options).pipe(
      tap(result => {
        this.patchState({
          allLoaded: !result.paginationInfo.hasBottom,
          hasBottom: result.paginationInfo.hasBottom
        });
      }),
      switchMap(result =>
        this.userGroupService.getUserGroupCount(this.get().account?.id).pipe(
          tap(userGroupCount => {
            if (this.get().firstNotZeroUserGroupCount === 0) {
              this.patchState({
                firstNotZeroUserGroupCount: userGroupCount.count
              });
            }
            this.patchState({
              userGroupsCount: userGroupCount.count,
              maxGroupCount: userGroupCount.maxGroupCount,
              loading: false
            });
          }),
          map(() => result)
        )
      ),
      catchError(error => {
        this.errorService.error('UserGroupListService', '', error, false, true);
        this.patchState({ loading: false, hasBottom: false });
        return of({
          data: [],
          paginationInfo: {
            hasBottom: false,
            hasTop: false,
            totalElementCount: 0
          }
        });
      })
    );
  }

  private createViewModel(state: PagedListState<UserGroup> & UserGroupsListState): UserGroupsListViewModel {
    const activeAccountAccess = state.user?.accountAccesses.find(access => access.accountId === state.account.id);
    return {
      ...state,
      userGroups: state.data,
      userGroupsCountFiltered: (state.paginationInfo as SaccPaginationResult)?.totalElementCount || 0,
      userCanManageGroup: state.user?.admin || state.enableAdminEditPrivilegesOverride || activeAccountAccess?.role === AccountUserRole.MANAGER,
      activeAccountAccess
    };
  }
}
