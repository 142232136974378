import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { SortDirection } from '@celum/common-components';
import { Admin, BatchDTO } from '@celum/sacc/domain';
import { SortablePaginationOption } from '@celum/shared/domain';
import { Result } from '@celum/shared/util';

import { RestService } from './rest.service';
import { Utils } from '../utils';

export interface AdminFindOptions extends SortablePaginationOption {
  name?: string;
}

@Injectable({ providedIn: 'root' })
export class AdminResourceService extends RestService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public search(options: Partial<AdminFindOptions> = { offset: 0, limit: 1 }): Observable<Result<Admin>> {
    const finalOptions = {
      ...options,
      offset: options?.offset || 0,
      limit: options?.limit || 250,
      sort: `${options?.sort?.active || 'promotedAt'},${options?.sort?.direction || SortDirection.DESC}`
    };
    const params = new HttpParams()
      .set('page', finalOptions.offset / finalOptions.limit)
      .set('size', finalOptions.limit)
      .set('sort', finalOptions.sort);

    return this.httpClient
      .post<BatchDTO<Admin>>(this.getApiUrl(`/admins/search`), { nameOrEmailContains: finalOptions?.name || '' }, { params })
      .pipe(map(result => Utils.mapToResult<Admin>(result, options.offset)));
  }

  public revokeAdminRights(oid: string): Observable<void> {
    return this.httpClient.delete<void>(this.getApiUrl(`/admins/${oid}`));
  }
}
