import { Sort } from '@angular/material/sort';

import { SortDirection } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

export interface RepositoryFilters extends SortablePaginationOption {
  repositoryId?: string;
  buildNumber?: string;
  ownerEmail?: string;
}

export function createEmptyRepositoryFilter(sort?: Sort): RepositoryFilters {
  return {
    repositoryId: '',
    buildNumber: '',
    ownerEmail: '',
    sort: sort || createDefaultRepositoryFilterSort()
  };
}

export function createDefaultRepositoryFilterSort(): Sort {
  return { active: 'repositoryId', direction: SortDirection.ASC };
}
