import { AsyncPipe } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { take } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, ExperiencePrivilege, UserState, WorkroomPrivilege } from '@celum/authentication';
import { AVATAR_SIZE, CelumDialogModule, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { CelumListModule } from '@celum/internal-components';
import { CelumDirectivesModule } from '@celum/ng2base';
import { Account, AccountMember } from '@celum/sacc/domain';
import { UserAvatarComponent } from '@celum/shared/ui-people';

import { EditAccountMemberDialogService } from './edit-account-member-dialog.service';
import { IconTextButtonComponent } from '../../icon-text-button/icon-text-button.component';
import { PrivilegeIconComponent, PrivilegeIconType } from '../../privilege-icon/privilege-icon.component';
import { AccountMemberStatusSwitcherComponent } from '../account-member-status-switcher/account-member-status-switcher.component';
import { GroupSelectorComponent } from '../group-selector/group-selector.component';
import { PrivilegeInfoPopupDialogComponent } from '../privilege-info-popup-dialog/privilege-info-popup-dialog.component';

export interface EditAccountMemberDialogData {
  editedMember: AccountMember;
  account: Account;
  currentUser: AccountMember;
}

@Component({
  selector: 'sacc-edit-account-member-dialog',
  templateUrl: './edit-account-member-dialog.component.html',
  styleUrl: './edit-account-member-dialog.component.scss',
  imports: [
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,

    CelumDialogModule,
    CelumDirectivesModule,
    CelumListModule,

    AccountMemberStatusSwitcherComponent,
    GroupSelectorComponent,
    IconTextButtonComponent,
    PrivilegeIconComponent,
    PrivilegeInfoPopupDialogComponent,
    UserAvatarComponent
  ],
  // Needed to style the layout of the dialog content correctly
  encapsulation: ViewEncapsulation.None,
  providers: [EditAccountMemberDialogService]
})
export class EditAccountMemberDialogComponent {
  private editedMember: AccountMember;
  protected AVATAR_SIZE = AVATAR_SIZE;

  protected readonly ExperiencePrivilege = ExperiencePrivilege;
  protected readonly AccountAccessStatus = AccountAccessStatus;
  protected readonly UserState = UserState;
  protected readonly WorkroomPrivilege = WorkroomPrivilege;
  protected readonly PrivilegeIconType = PrivilegeIconType;

  protected accountMemberForm: any;

  protected readonly iconInfo = new IconConfiguration('info-icon').withIconSize(12);

  constructor(
    private formBuilder: FormBuilder,
    protected service: EditAccountMemberDialogService,
    protected dialogRef: MatDialogRef<EditAccountMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: EditAccountMemberDialogData
  ) {
    // For now, we map the experience-privilege to our internal enum - in the BE it is stored only with FULL_ACCESS or null
    this.editedMember = {
      ...data.editedMember,
      privileges: {
        ...data.editedMember.privileges,
        experience: data.editedMember.privileges.experience === ExperiencePrivilege.FULL_ACCESS ? ExperiencePrivilege.FULL_ACCESS : ExperiencePrivilege.NONE
      }
    };

    this.service.init(this.editedMember, data.account, data.currentUser);

    this.accountMemberForm = this.formBuilder.nonNullable.group({
      role: this.formBuilder.nonNullable.control<AccountUserRole>(this.editedMember.role),
      experiencePrivilege: this.formBuilder.nonNullable.control<ExperiencePrivilege>(this.editedMember.privileges.experience),
      status: this.formBuilder.nonNullable.control<AccountAccessStatus>(this.editedMember.status),
      workroomPrivilege: this.formBuilder.nonNullable.control<WorkroomPrivilege>(this.editedMember.privileges.work)
    });

    if (this.editedMember.status === AccountAccessStatus.INACTIVE) {
      this.accountMemberForm.controls.role.disable();
      this.accountMemberForm.controls.experiencePrivilege.disable();
      this.accountMemberForm.controls.workroomPrivilege.disable();
    }

    this.accountMemberForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      if (DataUtil.isEmpty(this.service.getChangedValues(this.accountMemberForm.value, this.editedMember))) {
        this.accountMemberForm.markAsPristine();
      }

      this.service.patchState({ currentStatus: this.accountMemberForm.controls.status.value });
    });
  }

  protected prepareUserActivation(activate: boolean): void {
    this.accountMemberForm.controls.status.patchValue(activate ? AccountAccessStatus.ACTIVE : AccountAccessStatus.INACTIVE);
    this.accountMemberForm.controls.status.markAsDirty();
  }

  protected updateAccountMember(keepSyncFiles?: boolean): void {
    const changes = this.service.getChangedValues(this.accountMemberForm.value, this.editedMember);

    // Special case permissions: If at least work- or experience-permissions are changed, we need to provide both for the backend
    if (changes.privileges) {
      changes.privileges.experience = changes.privileges.experience || this.editedMember.privileges.experience;
      changes.privileges.work = changes.privileges.work || this.editedMember.privileges.work;
    }

    if (keepSyncFiles !== undefined) {
      changes.canKeepDriveScopes = keepSyncFiles;
    }

    this.service.updateAccountMember(changes);
  }

  protected getReadonlyRoleLabel(editedMember: AccountMember, account: Account): string {
    const roleKey = `COMPONENTS.ACCOUNT_MEMBER_TABLE.MEMBER_ROLE.${editedMember.role.toUpperCase()}`;
    return editedMember.email === account.ownerEmail ? `${roleKey}_OWNER` : roleKey;
  }

  protected onStatusSwitcherClicked(): void {
    this.service.vm$.pipe(take(1)).subscribe(vm => {
      const currentStatus = this.accountMemberForm.controls.status.value;
      if (vm.editedMember.status === AccountAccessStatus.ACTIVE && currentStatus === AccountAccessStatus.ACTIVE) {
        this.prepareUserActivation(false);
      } else if (vm.editedMember.status === AccountAccessStatus.INACTIVE && currentStatus === AccountAccessStatus.INACTIVE) {
        this.prepareUserActivation(true);
      }
    });
  }
}
